import SearchpackSekletonLoaderComponent from "../../common/skeletonLoader/searchpackSkeletonLoader.component"
import { SearchpackSelection } from "./searchpackSelection.component"

export const SearchpackTracker = () => {
    return (
        <>
            <div
                className={"flex gap-[20px] items-start w-full "}
                // style={{ width: "calc(100% - 60px)" }}
            >
                <SearchpackSelection />
                <SearchpackSekletonLoaderComponent />
            </div>
        </>
    )
}
