import { useDispatch } from "react-redux"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import icon from "../../../assets/gifs/new-blinking-logo.gif"
import useGetExtendedTrackpacks from "../../../hooks/reactQueryHooks.hook"
import { PrimaryText } from "../../elements/primaryText.element"

const SearchpackSkeletonLoader = () => {
    const dispatch = useDispatch()
    const { refetch } = useGetExtendedTrackpacks({}, {}, false)

    const handleLisitingReportFlow = () => {
        refetch()
        dispatch(
            RuntimeActionCreators.openListingAnalyzerReportModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    return (
        <div className="bg-white p-[16px]  rounded-[10px] border border-gray-200">
            <div className="border-2 border-[#088AB2] rounded-[4px]">
                <div className="max-w-[660px] w-full mx-auto pt-[61px] pb-[85px] px-[24px] flex flex-col items-center justify-center gap-[16px]">
                    <div className="w-[60px] h-[54px]">
                        <img src={icon} alt="blinking logo" className="h-full w-full" />
                    </div>
                    <PrimaryText size="sm-medium" weight="medium" className="leading-[24px] text-gray-600">
                        Welcome to my <span className="text=-[16px] font-[500] text-[#088AB2]">Search Tracker</span>
                    </PrimaryText>
                    <PrimaryText weight="light" size="small" className="text-center text-gray-600 max-w-[488px]">
                        Three uniquely powerful tools that allow you to analyze keyword performance alongside listing
                        change and market performance data
                    </PrimaryText>

                    <button
                        onClick={handleLisitingReportFlow}
                        type="button"
                        className=" mt-[16px] py-[8px] px-[16px] rounded-[10px] inline-flex items-center justify-center text-[#088AB2] font-[500] text-[14px] leading-[22px]"
                        style={{
                            background: "linear-gradient(117.03deg, #CFF9FE 0%, #A5F0FC 100%)",
                        }}
                    >
                        Select a Trackpack to get started
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SearchpackSkeletonLoader
