import { useEffect, useMemo, useRef, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Tooltip } from "react-tooltip"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { EditIcon } from "../../../assets/svgs/editIcon.svg"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { NotificationStateSelector } from "../../../selectors/notificationStateSelector"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import CardSkeletonLoader from "../../common/skeletonLoader/cardSkeleton.component"
import { ButtonElement } from "../../elements/button.element"
import { PrimaryText } from "../../elements/primaryText.element"
import CardCustomTooltipContent from "../tooltip-alerts/cardTooltipAlerts.component"
import CommonTooltip from "../tooltipItems/commonTooltip.component"

export const SearchpackAsinCard = (props: IAsinContrastCard) => {
    const navigate = useNavigate()
    const sidebarRef = useRef<HTMLDivElement>(null)
    const [showBorder, setShowBorder] = useState(false)

    // const selectedTrackpackID = localStorage.getItem("selectedTrackpackID")

    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading = useMemo(() => RuntimeHelper.productLoading(), [loading])
    const isTrackPackLoading: boolean = useMemo(() => RuntimeHelper.trackpackLoading(), [loading])

    const dispatch = useDispatch()
    const [selectedProduct, setSelectedProduct] = useState<IGroupProductData[]>([])
    const { notifiSelection } = useSelector(NotificationStateSelector)

    const [selectedAsins, setSelectedAsins] = useState(() => {
        const item = localStorage.getItem("notifiAsin")
        return item ? JSON.parse(item) : []
    })

    const SelectedProductFunc = (item: any) => {}

    useEffect(() => {
        const handleScroll = () => {
            if (sidebarRef.current) {
                setShowBorder(sidebarRef.current.scrollTop > 0)
            }
        }

        const currentSidebarRef = sidebarRef.current
        if (currentSidebarRef) {
            currentSidebarRef.addEventListener("scroll", handleScroll)
        }

        return () => {
            if (currentSidebarRef) {
                currentSidebarRef.removeEventListener("scroll", handleScroll)
            }
        }
    }, [])
    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openAsinAddtionalModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    return (
        <div className="w-full">
            <div className="relative bg-gray-50 w-full py-[12px] px-[12px] rounded-2xl outline outline-1 outline-gray-50 justify-start items-start inline-flex flex-col pb-0">
                <div
                    className={`w-full flex justify-between gap-[4px] items-center pb-[12px]  ${
                        showBorder ? "border-b border-gray-200 pb-[14px]" : ""
                    }`}
                >
                    <div className=" flex gap-[6px] items-center">
                        <PrimaryText size="md" weight={"medium"} className={"text-[#088AB2] pl-[8px]"}>
                            ASINs
                        </PrimaryText>
                    </div>
                    <div className="flex gap-[8px]">
                        <div
                            data-tooltip-id="dashboard-tooltip"
                            className="cursor-pointer outline-none"
                            data-tooltip-place="bottom"
                            data-tooltip-class-name={"!ml-[32px]"}
                            data-tooltip-offset={-23}
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <CommonTooltip className=" mt-[13px]" label="Settings" />
                            )}
                        >
                            <ButtonElement
                                viewType="only-icon"
                                className="rounded-[10px] bg-[#ECFDFF] hover:bg-cyan-100 p-[10px] gradient-border z-10"
                            >
                                <EditIcon />
                            </ButtonElement>
                        </div>
                    </div>
                </div>
                <div
                    ref={sidebarRef}
                    className={`w-[305px] ${
                        isTrackPackLoading ? "overflow-y-hidden" : "overflow-y-scroll"
                    } max-h-full productCardScroll`}
                >
                    <div className="flex flex-col gap-[8px] max-h-full" style={{ height: "calc(100vh - 358px)" }}>
                        <CardSkeletonLoader length={12} isTrackpack={false} />
                    </div>

                    <Tooltip
                        id="tracking-off-element"
                        style={{
                            padding: "4px 8px",
                            backgroundColor: "#FEF0C7",
                            borderRadius: "6px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 8px 2px #1018281A",
                        }}
                        place="right"
                        delayShow={200}
                        classNameArrow="custom-arrow yellow"
                        render={({ content }) => {
                            if (!content) return null
                            const warnings = JSON.parse(content).warnings
                            return <CardCustomTooltipContent id="tracking-off-element" data={warnings} />
                        }}
                    />

                    <Tooltip
                        id="tracking-error-card-element"
                        style={{
                            padding: "4px 8px",
                            backgroundColor: "#FEE4E2",
                            borderRadius: "6px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 8px 2px #1018281A",
                        }}
                        place="right"
                        delayShow={200}
                        classNameArrow="custom-arrow red"
                        render={({ content }) => {
                            if (!content) return null
                            const warnings = JSON.parse(content).warnings
                            return <CardCustomTooltipContent id="tracking-error-card-element" data={warnings} />
                        }}
                    />

                    <Tooltip
                        id="tracking-success-element"
                        style={{
                            padding: "4px 8px",
                            backgroundColor: "#DCFAE6",
                            borderRadius: "6px",
                            zIndex: 1000,
                            boxShadow: "0px 4px 8px 2px #1018281A",
                        }}
                        place="right"
                        delayShow={200}
                        classNameArrow="custom-arrow green"
                        render={({ content }) => {
                            if (!content) return null
                            const warnings = JSON.parse(content)
                            return <CardCustomTooltipContent id="tracking-success-element" data={warnings} />
                        }}
                    />

                    <Tooltip
                        id="status-warning-tooltip"
                        style={{
                            padding: "8px",
                            backgroundColor: "#FEF0C7",
                            borderRadius: "6px",
                            display: "inline-block",
                            zIndex: 10000,
                            boxShadow: "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                        }}
                        clickable
                        place="top"
                        delayShow={200}
                        classNameArrow="custom-arrow yellow"
                        render={({ content }) => {
                            if (!content) return null
                            return <CardCustomTooltipContent id="status-warning-tooltip" dataWarnings={content} />
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
