import ReactDOMServer from "react-dom/server"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { ReactComponent as AddIcon } from "../../../assets/svgs/new-add-icon.svg"
import { ButtonElement } from "../../elements/button.element"
import { PrimaryText } from "../../elements/primaryText.element"
import { SelectElement } from "../../elements/select.element"
import CommonTooltip from "../tooltipItems/commonTooltip.component"
import { SearchpackAsinCard } from "./searchpackAsinCard.component"

const options = [
    {
        label: "string",
        value: "string",
    },
]

export const SearchpackSelection = () => {
    const { control, watch, setValue } = useForm()
    const dispatch = useDispatch()

    const handleOpen = () => {}

    return (
        <>
            <div className="flex flex-col gap-[24px] w-[304px]">
                <div className="w-full">
                    <div className="w-full p-[12px] px-3 pt-3 justify-start items-start inline-flex rounded-[16px]  border border-gray-50 bg-gray-50 ">
                        <div className="w-full gap-[12px]  flex flex-col">
                            <div className="flex justify-between ">
                                <div className="flex gap-[6px] items-center">
                                    <PrimaryText
                                        // onClick={handleOpenTrackPack}
                                        weight="medium"
                                        size="sm-medium"
                                        className="text-[#088AB2] font- leading-normal pl-[8px]"
                                    >
                                        Searchpacks
                                    </PrimaryText>
                                    {/* <InfoLabel /> */}
                                </div>
                                <div
                                    className="cursor-pointer outline-none"
                                    data-tooltip-id="dashboard-tooltip"
                                    data-tooltip-place="bottom"
                                    data-tooltip-offset={-10}
                                    data-tooltip-class-name={"!ml-[38px]"}
                                    data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                        <CommonTooltip className="" label="Add Trackpack" />
                                    )}
                                >
                                    <ButtonElement
                                        onClick={handleOpen}
                                        viewType="only-icon"
                                        className="p-[10px] bg-gradient-to-br from-cyan-100 to-cyan-200 rounded-[10px] disabled:bg-gray-100
                                    hover:bg-gradient-to-r hover:from-cyan-200 hover:to-cyan-300
                                    active:bg-gradient-to-br active:from-cyan-300 active:to-cyan-400"
                                    >
                                        <AddIcon />
                                    </ButtonElement>
                                </div>
                            </div>
                            <div className="w-[280px] relative">
                                <SelectElement
                                    className="focus:outline focus:outline-cyan-300/30 pr-[8px] !w-[99%]"
                                    customBoxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(103, 227, 249, 0.20)"
                                    buttonTextClass="truncate whitespace-nowrap max-w-[220px] "
                                    reactHookControl={control}
                                    type="check"
                                    name="asins"
                                    rightCheckBox={true}
                                    placeholder={"Select Searchpack"}
                                    isToolitpShow={false}
                                    options={options}
                                    // onChange={handleSelectElementChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <SearchpackAsinCard />
            </div>
        </>
    )
}
