import AustraliaImg from "../assets/images/aus.jpg"
import BelgiumImg from "../assets/images/belgium.png"
import BrazilImg from "../assets/images/brazil.jpg"
import CanadaImg from "../assets/images/canada.jpg"
import ChineImg from "../assets/images/china.jpg"
import EgyptImg from "../assets/images/egypt.jpg"
import FranceImg from "../assets/images/france.jpg"
import GermanyImg from "../assets/images/germany.png"
import IndiaImg from "../assets/images/india.png"
import ItalyImg from "../assets/images/italy.png"
import JapanImg from "../assets/images/japan.png"
import MexicoImg from "../assets/images/maxico.jpg"
import NetherlandImg from "../assets/images/netherland.jpg"
import PolandImg from "../assets/images/poleand.png"
import SaudiaImg from "../assets/images/saudia.png"
import SpainImg from "../assets/images/spanin.jpg"
import SwedenImg from "../assets/images/sweden.jpg"
import TurkeyImg from "../assets/images/turkey.jpg"
import UaeImg from "../assets/images/uae.jpg"
import UkImg from "../assets/images/uk.png"

import SindaporeImg from "../assets/images/singapore.jpg"
import AsinChangeIcon from "../assets/svgs/filters/AsinChangeFilterIcon.svg"
import BulletIcon from "../assets/svgs/filters/BulletFilterIcon.svg"
import CarouselIcon from "../assets/svgs/filters/CarouselFilterIcon.svg"
import DescriptionIcon from "../assets/svgs/filters/DescriptionFilterIcon.svg"
import MainImageIcon from "../assets/svgs/filters/MainImageFilterIcon.svg"
import PriceIcon from "../assets/svgs/filters/PriceFilterIcon.svg"
import ProductVideoFilterIcon from "../assets/svgs/filters/ProductVideoFilterIcon.svg"
import StockIcon from "../assets/svgs/filters/StockFilterIcon.svg"
import TitleIcon from "../assets/svgs/filters/TitleFilterIcon.svg"
import SellerIcon from "../assets/svgs/filters/TopSellerFilterIcon.svg"
import VideoIcon from "../assets/svgs/filters/VideoFilterIcon.svg"
import UsaIcon from "../assets/svgs/usaIcon.png"

export const DashboardBoardConfig = {
    graphConfig: {
        productDifferentiateItems: [
            { value: "actual_asin", label: "ASIN", component: AsinChangeIcon, width: "32px" },
            { value: "seller_info", label: "Seller", component: SellerIcon, width: "32px" },
            { value: "stock", label: "Stock", component: StockIcon, width: "35px" },
            { value: "price", label: "Price", component: PriceIcon, width: "32px" },
            // { value: "keywords", label: "Keywords", component: KeywordsFilterIcon, width: "48px" },
            { value: "title", label: "Title", component: TitleIcon, width: "30px" },
            { value: "bullets", label: "Bullets", component: BulletIcon, width: "40px" },
            { value: "description", label: "Description", component: DescriptionIcon, width: "58px" },
            { value: "main_image", label: "Main Image", component: MainImageIcon, width: "59px" },
            { value: "carousel_images", label: "Carousel Images", component: CarouselIcon, width: "80px" },
            { value: "main_video", label: "Main Video", component: VideoIcon, width: "57px" },
            { value: "videos", label: "Product Videos", component: ProductVideoFilterIcon, width: "74px" },
        ],
        productResults: [
            { value: "bsr_large", label: "BSR L Cat", LogViewLabel: "BSR Large Category" },
            { value: "bsr_small", label: "BSR S Cat", LogViewLabel: "BSR Small Category" },
            { value: "ratings_count", label: "Ratings Count", LogViewLabel: "Ratings Count" },
            { value: "rating", label: "Review Score", LogViewLabel: "Review Score" },
        ],
        productRange: [
            { value: "daily", label: "Daily" },
            { value: "weekly", label: "Weekly" },
            { value: "monthly", label: "Monthly" },
        ],
        productRangeUpdated: [
            { value: 7, label: "1<span className='text-[9px]'>W</span>", labelName: "1 Week", width: "42px" },
            { value: 14, label: "2<span className='text-[9px]'>W</span>", labelName: "2 Week", width: "46px" },
            { value: 30, label: "1<span className='text-[9px]'>M</span>", labelName: "1 Month", width: "48px" },
            { value: 180, label: "6<span className='text-[9px]'>M</span>", labelName: "6 Months", width: "50px" },
            { value: 0, label: "A<span className='text-[9px]'>LL</span>", labelName: "All", width: "28px" },
        ],
    },
}

export const img_url = "https://amaizing.sfo3.digitaloceanspaces.com/public/images/"

export const countriesList = [
    {
        value: "USA",
        label: "USA",
        flagUrl: UsaIcon,
    },
    {
        value: "australia",
        label: "Australia",
        flagUrl: AustraliaImg,
    },
    {
        value: "belgium",
        label: "Belgium",
        flagUrl: BelgiumImg,
    },
    {
        value: "brazil",
        label: "Brazil",
        flagUrl: BrazilImg,
    },
    {
        value: "canada",
        label: "Canada",
        flagUrl: CanadaImg,
    },

    {
        value: "china",
        label: "China",
        flagUrl: ChineImg,
    },
    {
        value: "egypt",
        label: "Egypt",
        flagUrl: EgyptImg,
    },
    {
        value: "france",
        label: "France",
        flagUrl: FranceImg,
    },
    {
        value: "germany",
        label: "Germany",
        flagUrl: GermanyImg,
    },
    {
        value: "india",
        label: "India",
        flagUrl: IndiaImg,
    },
    {
        value: "italy",
        label: "Italy",
        flagUrl: ItalyImg,
    },

    {
        value: "japan",
        label: "Japan",
        flagUrl: JapanImg,
    },
    {
        value: "mexico",
        label: "Mexico",
        flagUrl: MexicoImg,
    },
    {
        value: "netherland",
        label: "Netherland",
        flagUrl: NetherlandImg,
    },
    {
        value: "poland",
        label: "Poland",
        flagUrl: PolandImg,
    },
    {
        value: "saudia_arabia",
        label: "Saudia Arabia",
        flagUrl: SaudiaImg,
    },
    {
        value: "singapore",
        label: "Singapore",
        flagUrl: SindaporeImg,
    },
    {
        value: "spain",
        label: "Spain",
        flagUrl: SpainImg,
    },
    {
        value: "sweden",
        label: "Sweden",
        flagUrl: SwedenImg,
    },
    {
        value: "turkey",
        label: "Turkey",
        flagUrl: TurkeyImg,
    },
    {
        value: "uae",
        label: "UAE",
        flagUrl: UaeImg,
    },
    {
        value: "united_kindom",
        label: "United Kindom",
        flagUrl: UkImg,
    },
]

export const AsinIssuesList = [
    "DIFFERENT_ASIN_RETURNED",
    "CHILD_ASIN_RETURNED",
    "DATA_UNAVAILABLE",
    "DIFFERENT_LARGE_CATEGORY",
]
export const ENABLE_LISTING_ANALYZER = true
export const SEARCHPACK_ICONS = false

export const reportsData = [
    {
        report_id: 1,
        report_type: "Production Brief",
        trackpack: {
            id: 1,
            name: "Heated Hair Irons",
            details: {
                asins: "1 User",
                competitors: "11 Competitor",
                archived: "0 Archived",
            },
        },
        products: [
            {
                asin: "B0842S5QWF",
                is_target: true,
                main_image_url: "710nRqp5zVL._AC_SL1500_.jpg",
            },
            {
                asin: "B0842S5QW3",
                is_target: false,
                main_image_url: "51LCY6TWRJL._AC_SL1500_.jpg",
            },
            {
                asin: "B0842S5QW4",
                is_target: false,
                main_image_url: "51LCY6TWRJL._AC_SL1500_.jpg",
            },
        ],
        created_at: "2024-09-16T12:46:14.768Z",
        any_response_last_updated_at: "2024-09-16T12:46:26.220Z",
        last_completed_at: "2024-09-16T12:46:36.578Z",
        completion_percentage: 50,
    },
    {
        report_id: 2,
        report_type: "Main_Image",
        trackpack: {
            id: 2,
            name: "Straightening Brushes",
            details: {
                asins: "2 Users",
                competitors: "8 Competitors",
                archived: "1 Archived",
            },
        },
        products: [
            {
                asin: "B0842S5QWF",
                is_target: true,
                main_image_url: "710nRqp5zVL._AC_SL1500_.jpg",
            },
            {
                asin: "B0842S5QW3",
                is_target: false,
                main_image_url: "51LCY6TWRJL._AC_SL1500_.jpg",
            },
            {
                asin: "B0842S5QW4",
                is_target: false,
                main_image_url: "51LCY6TWRJL._AC_SL1500_.jpg",
            },
        ],
        created_at: "2024-09-16T12:46:14.768Z",
        any_response_last_updated_at: "2024-09-16T12:46:26.220Z",
        last_completed_at: "2024-09-16T12:46:36.578Z",
        completion_percentage: 80,
    },
    {
        report_id: 3,
        report_type: "Main_Image",
        trackpack: {
            id: 2,
            name: "Curling Wands",
            details: {
                asins: "3 Users",
                competitors: "5 Competitors",
                archived: "2 Archived",
            },
        },
        products: [
            {
                asin: "B0842S5QWF",
                is_target: true,
                main_image_url: "710nRqp5zVL._AC_SL1500_.jpg",
            },
            {
                asin: "B0842S5QW3",
                is_target: false,
                main_image_url: "51LCY6TWRJL._AC_SL1500_.jpg",
            },
            {
                asin: "B0842S5QW4",
                is_target: false,
                main_image_url: "51LCY6TWRJL._AC_SL1500_.jpg",
            },
        ],
        created_at: "2024-09-16T12:46:14.768Z",
        any_response_last_updated_at: "2024-09-16T12:46:26.220Z",
        last_completed_at: "2024-09-16T12:46:36.578Z",
        completion_percentage: 100,
    },
    {
        report_id: 4,
        report_type: "Main_Image",
        trackpack: {
            id: 2,
            name: "Blow Dryers",
            details: {
                asins: "4 Users",
                competitors: "15 Competitors",
                archived: "3 Archived",
            },
        },
        products: [
            {
                asin: "B0842S5QWF",
                is_target: true,
                main_image_url: "710nRqp5zVL._AC_SL1500_.jpg",
            },
            {
                asin: "B0842S5QW3",
                is_target: false,
                main_image_url: "51LCY6TWRJL._AC_SL1500_.jpg",
            },
            {
                asin: "B0842S5QW4",
                is_target: false,
                main_image_url: "51LCY6TWRJL._AC_SL1500_.jpg",
            },
        ],
        created_at: "2024-09-16T12:46:14.768Z",
        any_response_last_updated_at: "2024-09-16T12:46:26.220Z",
        last_completed_at: "2024-09-16T12:46:36.578Z",
        completion_percentage: 40,
    },
    {
        report_id: 4,
        report_type: "Main_Image",
        trackpack: {
            id: 2,
            name: "Hair Clippers",
            details: {
                asins: "5 Users",
                competitors: "20 Competitors",
                archived: "0 Archived",
            },
        },
        products: [
            {
                asin: "B0842S5QWF",
                is_target: true,
                main_image_url: "710nRqp5zVL._AC_SL1500_.jpg",
            },
            {
                asin: "B0842S5QW3",
                is_target: false,
                main_image_url: "51LCY6TWRJL._AC_SL1500_.jpg",
            },
            {
                asin: "B0842S5QW4",
                is_target: false,
                main_image_url: "51LCY6TWRJL._AC_SL1500_.jpg",
            },
        ],
        created_at: "2024-09-16T12:46:14.768Z",
        any_response_last_updated_at: "2024-09-16T12:46:26.220Z",
        last_completed_at: "2024-09-16T12:46:36.578Z",
        completion_percentage: 100,
    },
]

export const reportDataByID = {
    report_id: 7,
    report_type: "MAIN_IMAGE_ANALYSIS",
    template_id: 1,
    trackpack: {
        asin_counts: {
            own: 0,
            competitor: 3,
            archived: 1,
        },
        id: 396,
        name: "test301",
        amazon_tld: "co.uk",
    },
    products: [
        {
            report_product_id: 19,
            asin: "B0CN3PPHGT",
            main_image_filename: "81YJjU0mdqL._AC_SL1500_.jpg",
            is_target: false,
            order: 1,
        },
        {
            report_product_id: 20,
            asin: "B0BL42JT9W",
            main_image_filename: "712WzmTKMtL._AC_SL1500_.jpg",
            is_target: false,
            order: 2,
        },
        {
            report_product_id: 21,
            asin: "B0CRRX2LH8",
            main_image_filename: "81gE3VGrrcL._AC_SL1500_.jpg",
            is_target: true,
            order: 3,
        },
    ],
    completion_percentage: 0,
    summary: 3,
    any_response_last_updated_at: null,
    first_completed_at: null,
    created_at: "2024-12-04T14:06:20.880Z",
    report_questions: [
        {
            report_question_id: 91,
            report_product_id: 19,
            is_final: false,
            question: {
                type: "CLICKABILITY",
                id: 1,
                score_prompt:
                    "Looking at your two selected ASINs compared to your Target ASIN,\nhow likely are you to click on the highlighted main image?",
                score_label_low: "Not likely",
                score_label_high: "Very likely",
                reason_prompt: "I’ve given this clickability score because...",
                suggestion_prompt: "This main image would be more clickable if...",
                order: 1,
                created_at: "2024-12-03T01:20:43.683Z",
                last_updated_at: "2024-12-03T01:20:43.683Z",
            },
            response: null,
        },
        {
            report_question_id: 92,
            report_product_id: 20,
            is_final: false,
            question: {
                type: "CLICKABILITY",
                id: 1,
                score_prompt:
                    "Looking at your two selected ASINs compared to your Target ASIN,\nhow likely are you to click on the highlighted main image?",
                score_label_low: "Not likely",
                score_label_high: "Very likely",
                reason_prompt: "I’ve given this clickability score because...",
                suggestion_prompt: "This main image would be more clickable if...",
                order: 1,
                created_at: "2024-12-03T01:20:43.683Z",
                last_updated_at: "2024-12-03T01:20:43.683Z",
            },
            response: null,
        },
        {
            report_question_id: 93,
            report_product_id: 21,
            is_final: false,
            question: {
                type: "CLICKABILITY",
                id: 1,
                score_prompt:
                    "Looking at your two selected ASINs compared to your Target ASIN,\nhow likely are you to click on the highlighted main image?",
                score_label_low: "Not likely",
                score_label_high: "Very likely",
                reason_prompt: "I’ve given this clickability score because...",
                suggestion_prompt: "This main image would be more clickable if...",
                order: 1,
                created_at: "2024-12-03T01:20:43.683Z",
                last_updated_at: "2024-12-03T01:20:43.683Z",
            },
            response: null,
        },
        {
            report_question_id: 94,
            report_product_id: 19,
            is_final: false,
            question: {
                type: "DIFFERENTIATION",
                id: 2,
                score_prompt: "How different is this main image compared to the others?",
                score_label_low: "Not different",
                score_label_high: "Very different",
                reason_prompt: "I’ve given this differentiation score because...",
                suggestion_prompt: "This main image would be more different if...",
                order: 2,
                created_at: "2024-12-03T01:20:43.683Z",
                last_updated_at: "2024-12-03T01:20:43.683Z",
            },
            response: null,
        },
        {
            report_question_id: 95,
            report_product_id: 20,
            is_final: false,
            question: {
                type: "DIFFERENTIATION",
                id: 2,
                score_prompt: "How different is this main image compared to the others?",
                score_label_low: "Not different",
                score_label_high: "Very different",
                reason_prompt: "I’ve given this differentiation score because...",
                suggestion_prompt: "This main image would be more different if...",
                order: 2,
                created_at: "2024-12-03T01:20:43.683Z",
                last_updated_at: "2024-12-03T01:20:43.683Z",
            },
            response: null,
        },
        {
            report_question_id: 96,
            report_product_id: 21,
            is_final: false,
            question: {
                type: "DIFFERENTIATION",
                id: 2,
                score_prompt: "How different is this main image compared to the others?",
                score_label_low: "Not different",
                score_label_high: "Very different",
                reason_prompt: "I’ve given this differentiation score because...",
                suggestion_prompt: "This main image would be more different if...",
                order: 2,
                created_at: "2024-12-03T01:20:43.683Z",
                last_updated_at: "2024-12-03T01:20:43.683Z",
            },
            response: null,
        },
        {
            report_question_id: 97,
            report_product_id: 19,
            is_final: false,
            question: {
                type: "FEATURE_CLARITY",
                id: 3,
                score_prompt: "How clear are the product’s features in this main image compared to the others?",
                score_label_low: "Not clear",
                score_label_high: "Very clear",
                reason_prompt: "I’ve given this score for clarity of product features because...",
                suggestion_prompt: "This main image’s product features would be clearer if...",
                order: 3,
                created_at: "2024-12-03T01:20:43.683Z",
                last_updated_at: "2024-12-03T01:20:43.683Z",
            },
            response: null,
        },
        {
            report_question_id: 98,
            report_product_id: 20,
            is_final: false,
            question: {
                type: "FEATURE_CLARITY",
                id: 3,
                score_prompt: "How clear are the product’s features in this main image compared to the others?",
                score_label_low: "Not clear",
                score_label_high: "Very clear",
                reason_prompt: "I’ve given this score for clarity of product features because...",
                suggestion_prompt: "This main image’s product features would be clearer if...",
                order: 3,
                created_at: "2024-12-03T01:20:43.683Z",
                last_updated_at: "2024-12-03T01:20:43.683Z",
            },
            response: null,
        },
        {
            report_question_id: 99,
            report_product_id: 21,
            is_final: false,
            question: {
                type: "FEATURE_CLARITY",
                id: 3,
                score_prompt: "How clear are the product’s features in this main image compared to the others?",
                score_label_low: "Not clear",
                score_label_high: "Very clear",
                reason_prompt: "I’ve given this score for clarity of product features because...",
                suggestion_prompt: "This main image’s product features would be clearer if...",
                order: 3,
                created_at: "2024-12-03T01:20:43.683Z",
                last_updated_at: "2024-12-03T01:20:43.683Z",
            },
            response: null,
        },
        {
            report_question_id: 100,
            report_product_id: 19,
            is_final: false,
            question: {
                type: "PRODUCT_QUALITY",
                id: 4,
                score_prompt: "How clear is the product’s quality from this main image compared to the others?",
                score_label_low: "Not clear",
                score_label_high: "Very clear",
                reason_prompt: "I’ve given this score for clarity of product quality because...",
                suggestion_prompt:
                    "How would you rate the production values of this main image compared to the others?",
                order: 4,
                created_at: "2024-12-03T01:20:43.683Z",
                last_updated_at: "2024-12-03T01:20:43.683Z",
            },
            response: null,
        },
        {
            report_question_id: 101,
            report_product_id: 20,
            is_final: false,
            question: {
                type: "PRODUCT_QUALITY",
                id: 4,
                score_prompt: "How clear is the product’s quality from this main image compared to the others?",
                score_label_low: "Not clear",
                score_label_high: "Very clear",
                reason_prompt: "I’ve given this score for clarity of product quality because...",
                suggestion_prompt:
                    "How would you rate the production values of this main image compared to the others?",
                order: 4,
                created_at: "2024-12-03T01:20:43.683Z",
                last_updated_at: "2024-12-03T01:20:43.683Z",
            },
            response: null,
        },
        {
            report_question_id: 102,
            report_product_id: 21,
            is_final: false,
            question: {
                type: "PRODUCT_QUALITY",
                id: 4,
                score_prompt: "How clear is the product’s quality from this main image compared to the others?",
                score_label_low: "Not clear",
                score_label_high: "Very clear",
                reason_prompt: "I’ve given this score for clarity of product quality because...",
                suggestion_prompt:
                    "How would you rate the production values of this main image compared to the others?",
                order: 4,
                created_at: "2024-12-03T01:20:43.683Z",
                last_updated_at: "2024-12-03T01:20:43.683Z",
            },
            response: null,
        },
        {
            report_question_id: 103,
            report_product_id: 19,
            is_final: false,
            question: {
                type: "PRODUCTION_VALUE",
                id: 5,
                score_prompt: "How would you rate the production values of this main image compared to the others?",
                score_label_low: "Very low",
                score_label_high: "Very high",
                reason_prompt: "I’ve given this score for production values because...",
                suggestion_prompt: "This main image’s production values would be better if...",
                order: 5,
                created_at: "2024-12-03T01:20:43.683Z",
                last_updated_at: "2024-12-03T01:20:43.683Z",
            },
            response: null,
        },
        {
            report_question_id: 104,
            report_product_id: 20,
            is_final: false,
            question: {
                type: "PRODUCTION_VALUE",
                id: 5,
                score_prompt: "How would you rate the production values of this main image compared to the others?",
                score_label_low: "Very low",
                score_label_high: "Very high",
                reason_prompt: "I’ve given this score for production values because...",
                suggestion_prompt: "This main image’s production values would be better if...",
                order: 5,
                created_at: "2024-12-03T01:20:43.683Z",
                last_updated_at: "2024-12-03T01:20:43.683Z",
            },
            response: null,
        },
        {
            report_question_id: 105,
            report_product_id: 21,
            is_final: true,
            question: {
                type: "PRODUCTION_VALUE",
                id: 5,
                score_prompt: "How would you rate the production values of this main image compared to the others?",
                score_label_low: "Very low",
                score_label_high: "Very high",
                reason_prompt: "I’ve given this score for production values because...",
                suggestion_prompt: "This main image’s production values would be better if...",
                order: 5,
                created_at: "2024-12-03T01:20:43.683Z",
                last_updated_at: "2024-12-03T01:20:43.683Z",
            },
            response: null,
        },
    ],
}

export const abc = [
    {
        status: "ACTIVE",
        asin: "B00KY3WAK6",
        own_product: true,
        latest_data: {
            title: "Professional Leather Hole Puncher Tool for Belts, Watch Bands, Handbag Straps and More, Precision Multi-Size Fabric & Leather Hole Puncher for Crafts & Easy DIY Belt Holes Hand-held",
            main_image_filename: "71NmuaPIeOL._SL1500_.jpg",
            currency: "USD",
            price: 19.97,
            rating: 4.6,
            ratings_total: 15518,
        },
        metadata: {
            id: 1,
            user: 4,
            name: "Klavdiya's Alpha TrackPack",
            status: "ACTIVE",
            amazon_tld: "com",
            large_category: "Tools & Home Improvement",
            created_at: "2024-05-02T07:52:42.987Z",
            last_updated_at: "2024-09-27T15:42:08.384Z",
            next_tracking_order: 11,
            user_first_name: "Klavdiya",
            user_last_name: "",
        },
        currentIssue: "DIFFERENT_LARGE_CATEGORY",
        color: {
            default: "#088AB2",
            hover: "#0E7090",
            active: "#67E3F9",
            inActive: "#06AED4",
            bgHundred: "#CFF9FE",
        },
    },
    {
        status: "ACTIVE",
        asin: "B06XRDBGY6",
        own_product: false,
        latest_data: {
            title: "Revolving Punch Plier Kit, XOOL Leather Hole Punch Set for Belts, Watch Bands, Straps, Dog Collars, Saddles, Shoes, Fabric, DIY Home or Craft Projects, Heavy Duty Rotary Puncher, Multi Hole Sizes Make Hole Puncher",
            main_image_filename: "715iPHTDJgL._AC_SL1500_.jpg",
            currency: "USD",
            price: 14.39,
            rating: 4.6,
            ratings_total: 17887,
        },
        metadata: {
            id: 1,
            user: 4,
            name: "Klavdiya's Alpha TrackPack",
            status: "ACTIVE",
            amazon_tld: "com",
            large_category: "Tools & Home Improvement",
            created_at: "2024-05-02T07:52:42.987Z",
            last_updated_at: "2024-09-27T15:42:08.384Z",
            next_tracking_order: 11,
            user_first_name: "Klavdiya",
            user_last_name: "",
        },
        currentIssue: "DIFFERENT_LARGE_CATEGORY",
        color: {
            default: "#4CA30D",
            hover: "#3B7C0F",
            active: "#A6EF67",
            inActive: "#66C61C",
            bgHundred: "#E4FBCC",
        },
    },
    {
        status: "ACTIVE",
        asin: "B00004T7WS",
        own_product: false,
        latest_data: {
            title: "General Tools Leather Hole Punch Tool - 6 Multi-Hole Sizes for Leather, Rubber, & Plastic - Hobbies & Crafts 8.5 inches",
            main_image_filename: "61mZtlRl-FL._AC_SL1500_.jpg",
            currency: "USD",
            price: 14.58,
            rating: 4.5,
            ratings_total: 19360,
        },
        metadata: {
            id: 1,
            user: 4,
            name: "Klavdiya's Alpha TrackPack",
            status: "ACTIVE",
            amazon_tld: "com",
            large_category: "Tools & Home Improvement",
            created_at: "2024-05-02T07:52:42.987Z",
            last_updated_at: "2024-09-27T15:42:08.384Z",
            next_tracking_order: 11,
            user_first_name: "Klavdiya",
            user_last_name: "",
        },
        currentIssue: null,
        color: {
            default: "#DD2590",
            hover: "#C11574",
            active: "#FAA7E0",
            inActive: "#EE46BC",
            bgHundred: "#FCE7F6",
        },
    },
    {
        status: "ACTIVE",
        asin: "B08YRJ4DY4",
        own_product: false,
        latest_data: {
            title: "KingTool Leather Hole Punch Set, Super Heavy Duty Rotary Puncher, Multi Hole Sizes Maker Tool Red - Professional Leather Hole Punch Set",
            main_image_filename: "71ClQ9iRlZS._SL1500_.jpg",
            currency: "USD",
            price: 19.79,
            rating: 4.7,
            ratings_total: 1173,
        },
        metadata: {
            id: 1,
            user: 4,
            name: "Klavdiya's Alpha TrackPack",
            status: "ACTIVE",
            amazon_tld: "com",
            large_category: "Tools & Home Improvement",
            created_at: "2024-05-02T07:52:42.987Z",
            last_updated_at: "2024-09-27T15:42:08.384Z",
            next_tracking_order: 11,
            user_first_name: "Klavdiya",
            user_last_name: "",
        },
        currentIssue: "DIFFERENT_LARGE_CATEGORY",
        color: {
            default: "#6938EF",
            hover: "#5925DC",
            active: "#BDB4FE",
            inActive: "#7A5AF8",
            bgHundred: "#EBE9FE",
        },
    },
    {
        status: "ACTIVE",
        asin: "B08VN7W1JR",
        own_product: false,
        latest_data: {
            title: "UNCO- Leather Hole Punch Tool, Multi Hole Sizes for Belts and Leather Black",
            main_image_filename: "71xAcu8FU9L._SL1500_.jpg",
            currency: "USD",
            price: 8.85,
            rating: 4.3,
            ratings_total: 3669,
        },
        metadata: {
            id: 1,
            user: 4,
            name: "Klavdiya's Alpha TrackPack",
            status: "ACTIVE",
            amazon_tld: "com",
            large_category: "Tools & Home Improvement",
            created_at: "2024-05-02T07:52:42.987Z",
            last_updated_at: "2024-09-27T15:42:08.384Z",
            next_tracking_order: 11,
            user_first_name: "Klavdiya",
            user_last_name: "",
        },
        currentIssue: null,
        color: {
            default: "#E04F16",
            hover: "#B93815",
            active: "#F7B27A",
            inActive: "#EF6820",
            bgHundred: "#FDEAD7",
        },
    },
    {
        status: "ACTIVE",
        asin: "B07H27S9R9",
        own_product: false,
        latest_data: {
            title: 'Hole Punch, NEU MASTER Heavy Duty Leather Hole Puncher for Belts, Watch Bands, Straps, Dog Collars, Saddles, Shoes, Fabric, DIY Home or Craft Projects 5/64" to 3/16"Hole Punches',
            main_image_filename: "61tzCdR4VCL._SL1500_.jpg",
            currency: "USD",
            price: 9.99,
            rating: 4.6,
            ratings_total: 2543,
        },
        metadata: {
            id: 1,
            user: 4,
            name: "Klavdiya's Alpha TrackPack",
            status: "ACTIVE",
            amazon_tld: "com",
            large_category: "Tools & Home Improvement",
            created_at: "2024-05-02T07:52:42.987Z",
            last_updated_at: "2024-09-27T15:42:08.384Z",
            next_tracking_order: 11,
            user_first_name: "Klavdiya",
            user_last_name: "",
        },
        currentIssue: "DIFFERENT_LARGE_CATEGORY",
        color: {
            default: "#1570EF",
            hover: "#175CD3",
            active: "#84CAFF",
            inActive: "#2E90FA",
            bgHundred: "#D1E9FF",
        },
    },
    {
        status: "ACTIVE",
        asin: "B0953VW517",
        own_product: false,
        latest_data: {
            title: "TOWOT Belt Hole Puncher Kit, Upgraded Version Leather Hole Punch for Belt, Saddles, Shoes, Fabric, DIY & Craft Projects, 6 Holes Heavy Duty Rotary Puncher, Easily Punches Perfect Round Holes orange",
            main_image_filename: "614gtuBeQrL._SL1500_.jpg",
            currency: "USD",
            price: 12.99,
            rating: 4.6,
            ratings_total: 2535,
        },
        metadata: {
            id: 1,
            user: 4,
            name: "Klavdiya's Alpha TrackPack",
            status: "ACTIVE",
            amazon_tld: "com",
            large_category: "Tools & Home Improvement",
            created_at: "2024-05-02T07:52:42.987Z",
            last_updated_at: "2024-09-27T15:42:08.384Z",
            next_tracking_order: 11,
            user_first_name: "Klavdiya",
            user_last_name: "",
        },
        currentIssue: "DIFFERENT_LARGE_CATEGORY",
        color: {
            default: "#E31B54",
            hover: "#C01048",
            active: "#FEA3B4",
            inActive: "#F63D68",
            bgHundred: "#FFE4E8",
        },
    },
    {
        status: "ACTIVE",
        asin: "B0C5M77B98",
        own_product: false,
        latest_data: {
            title: "KingTool 2 in 1 Professional Leather Hole Punch with Grommet kit - Perfect for Belts, Watch Bands, Straps, Dog Collars, Saddles, Shoes, Fabric, DIY Home or Craft Projects",
            main_image_filename: "71-uNFt4gKL._SL1500_.jpg",
            currency: "USD",
            price: 19.99,
            rating: 4.7,
            ratings_total: 1173,
        },
        metadata: {
            id: 1,
            user: 4,
            name: "Klavdiya's Alpha TrackPack",
            status: "ACTIVE",
            amazon_tld: "com",
            large_category: "Tools & Home Improvement",
            created_at: "2024-05-02T07:52:42.987Z",
            last_updated_at: "2024-09-27T15:42:08.384Z",
            next_tracking_order: 11,
            user_first_name: "Klavdiya",
            user_last_name: "",
        },
        currentIssue: "DIFFERENT_LARGE_CATEGORY",
        color: {
            default: "#099250",
            hover: "#087443",
            active: "#73E2A3",
            inActive: "#16B364",
            bgHundred: "#D3F8DF",
        },
    },
    {
        status: "ACTIVE",
        asin: "B06ZXYSCYZ",
        own_product: false,
        latest_data: {
            title: "Professional Leather Hole Puncher, Full Set Punch Tool Kits for Belt, Watch Band, Shoe, Crafting, Handbag Straps, Fabric, with Extra Punch Plates and Ruler, Multi Hole Sizes Make - Blue",
            main_image_filename: "715A-c8P1HL._AC_SL1500_.jpg",
            currency: "USD",
            price: 9.99,
            rating: 4.5,
            ratings_total: 7012,
        },
        metadata: {
            id: 1,
            user: 4,
            name: "Klavdiya's Alpha TrackPack",
            status: "ACTIVE",
            amazon_tld: "com",
            large_category: "Tools & Home Improvement",
            created_at: "2024-05-02T07:52:42.987Z",
            last_updated_at: "2024-09-27T15:42:08.384Z",
            next_tracking_order: 11,
            user_first_name: "Klavdiya",
            user_last_name: "",
        },
        currentIssue: null,
        color: {
            default: "#3E4784",
            hover: "#363F72",
            active: "#B3B8DB",
            inActive: "#4E5BA6",
            bgHundred: "#EAECF5",
        },
    },
    {
        status: "ACTIVE",
        asin: "B08VHZRVHM",
        own_product: false,
        latest_data: {
            title: "Leather Hole Punch Tool Set Heavy Duty 6 Size Revolving Leather Belt Hand Hole Puncher for Belts, Watch Bands, Straps, Dog Collars, Saddles, Shoes, Fabric, DIY Home or Craft Projects (9'', Silver/Red) 9'' A Silver/Red",
            main_image_filename: "51VznfQMAeL._AC_SL1500_.jpg",
            currency: "USD",
            price: 6.99,
            rating: 3.8,
            ratings_total: 1222,
        },
        metadata: {
            id: 1,
            user: 4,
            name: "Klavdiya's Alpha TrackPack",
            status: "ACTIVE",
            amazon_tld: "com",
            large_category: "Tools & Home Improvement",
            created_at: "2024-05-02T07:52:42.987Z",
            last_updated_at: "2024-09-27T15:42:08.384Z",
            next_tracking_order: 11,
            user_first_name: "Klavdiya",
            user_last_name: "",
        },
        currentIssue: null,
        color: {
            default: "#57534E",
            hover: "#44403C",
            active: "#D7D3D0",
            inActive: "#79716B",
            bgHundred: "#F5F5F4",
        },
    },
    {
        status: "ACTIVE",
        asin: "B0CBLMPX92",
        own_product: false,
        latest_data: {
            title: "Leather Hole Punch,Belt Hole Puncher for Leather,Heavy Duty Multi-Size Leather Punch with 6 Holes,Leather Punch Tool for Belts,Crafts & Easy DIY Flat+Round",
            main_image_filename: "61PntnG8MtL._AC_SL1002_.jpg",
            currency: "USD",
            price: 9.99,
            rating: 4.5,
            ratings_total: 200,
        },
        metadata: {
            id: 1,
            user: 4,
            name: "Klavdiya's Alpha TrackPack",
            status: "ACTIVE",
            amazon_tld: "com",
            large_category: "Tools & Home Improvement",
            created_at: "2024-05-02T07:52:42.987Z",
            last_updated_at: "2024-09-27T15:42:08.384Z",
            next_tracking_order: 11,
            user_first_name: "Klavdiya",
            user_last_name: "",
        },
        currentIssue: null,
        color: {
            default: "#0E9384",
            hover: "#107569",
            active: "#5FE9D0",
            inActive: "#15B79E",
            bgHundred: "#CCFBEF",
        },
    },
]

export const Semi_Automated_Keyowrds_Flag = true
